<template>
<div class="selectUser">
    <Layout>
        <Sider hide-trigger>
            <LiefengTree height="480" :fixedmenu="true" treeID="mychild2" style="width: 100%;" ref="mychild" @handleData="getList">
            </LiefengTree>
        </Sider>
        <Layout>
            <Content style="background:white;border:1px solid rgba(000,000,000,.1);margin-left:20px;">
                <div class="content">
                    <div class="tabList">
                        <Input :maxlength="20" enter-button v-model.trim="searchData.name" placeholder="姓名" style="width: 150px;margin:0 0 0 20px" @on-enter="getList" />
                        <Input :maxlength="20" enter-button v-model.trim="searchData.phone" placeholder="手机号" style="width: 200px;margin:0 10px" @on-enter="getList" />
                        <Button type="primary" icon="ios-search" @click="getList">查询</Button>
                        <Button style="margin:0 5px 0 13px" type="success" icon="ios-refresh" @click="rest">重置</Button>
                        <Table ref="selection" :columns="talbeColumns" :data="tableData" @on-select="onSelect" @on-select-cancel="onSelectCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" style="padding-top: 14px"></Table>
                        <Page class="page" show-total @on-change="currentPage" @on-page-size-change="hadlePageSize" :total="total" size="small" :page-size.sync="pageSize" :current="curPage" show-elevator />

                    </div>
                    <div class="buttonList">
                        <Button @click="removeAll">清空</Button>
                    </div>
                    <div class="selectList">
                        <Tree :data="sortSelect"></Tree>
                    </div>
                </div>
            </Content>
        </Layout>
    </Layout>
</div>
</template>

<script>
import LiefengTree from '@/components/LiefengTree'

export default {
    name: "selectUser",
    components: {
        LiefengTree,
    },
    data() {
        return {
            //tree菜单显示与隐藏
        cascaderFocusShow: false,
        //固定菜单标识
        fixedMenu: false,
            status: 2,

            selectModal: false,
            total: 0,
            pageSize: 10,
            curPage: 1,
            selection: [],
            sortSelect: [],
            fruit: [],
            nodeList: [
                // {
                //     value: "New York",
                //     label: "纽约",
                // },
            ],
            nodeId: "",
            searchData: {
                pageSize: 10,
                page: 1,
                name: "",
                phone: ""
            },
            // 树形控件data
            talbeColumns: [{
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                },
                {
                    title: "住房编号",
                    key: "familyNo",
                    sortable: true,
                    width: 150,

                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    sortable: true,
                    align: "center",
                    width: 100,
                },
                {
                    title: "性别",
                    key: "sex",
                    sortable: true,
                    align: "center",
                    width: 200,
                },
                {
                    title: "手机号",
                    key: "phone",
                    sortable: true,
                    align: "center",
                    width: 200,
                },
            ],
            tableData: [],
            treeData: [],

            // 表单数据
            formLeft: {
                input1: "",
                input2: "",
                input3: "",
            },
            tempArr: [],
        };
    },
    methods: {
             //固定菜单树tree
    fixedTree(bool) {
        if(bool) {
            this.$refs.baseList.$children[0].$children[1].$el.style.paddingLeft = '222px';
        }else {
            this.$refs.baseList.$children[0].$children[1].$el.style.paddingLeft = '0';
        }
        this.fixedMenu = bool;
        this.cascaderFocusShow = bool;
        
    },
      //鼠标悬停显示tree菜单
      cascaderMouse() {
          this.cascaderFocusShow = true;
      },
      //页面点击隐藏tree菜单
    pageClick() {
        //判断是否固定菜单
        if(!this.fixedMenu) {
          this.cascaderFocusShow = false;
        }
    },
        getTableData(data) {
            this.tableData = data;

            this.total = data.maxCount;
            this.handleTableChecked(this.tableData) // 恢复有勾选的数据

        },
        rest() {
            this.searchData.phone = '';
            this.searchData.name = '';
            this.curPage = 1
            // this.$refs.mychild.handleTree();
            this.getList()
        },
        getList(data) {
            if (data) {
                if (data.communityCode) {
                     this.$emit('communityCode',data.communityCode);
                    this.tableData = []
                    this.curPage = 1
                    this.pageSize = 10
                    this.treeParams = {
                        ...data,
                        status: 2, //必填
                        name: this.searchData.name,
                        phone: this.searchData.phone,
                        oemCode: parent.vue.oemInfo.oemCode,
                        page: data.page,
                        pageSize: data.pageSize,
                    }
                    this.curPage = data.page
                    this.pageSize = data.pageSize
                }
                if (data.page) {
                    this.treeParams.page = data.page
                    this.treeParams.pageSize = data.pageSize
                    this.treeParams.name = this.searchData.name;
                    this.treeParams.phone = this.searchData.phone;
                }
                if (data.cancelable) { //点击查询时  查询返回第一页的数据
                    this.treeParams.page = 1
                    this.treeParams.name = this.searchData.name;
                    this.treeParams.phone = this.searchData.phone;
                    this.curPage = 1
                }

            } else {
                this.treeParams = {
                    communityCode: this.treeParams.communityCode,
                    roadCode: this.treeParams.roadCode,
                    status: 2, //必填
                    name: this.searchData.name,
                    phone: this.searchData.phone,
                    oemCode: parent.vue.oemInfo.oemCode,
                    page: this.curPage,
                    pageSize: this.pageSize,
                }
            }


            this.$get("/syaa/pc/sy/user/residents/selectSyFamilyMemberPage", this.treeParams)
                .then((res) => {
                    if (res.code === "200") {
                        this.tableData = res.dataList;
                        this.getTableData(this.tableData)
                        this.total = res.maxCount;
                        this.curPage = res.currentPage
                        this.treeParams.name = ''
                        this.treeParams.phone = ''
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        select(data) {
            this.$emit("handleSelect", data)
            this.selection = data;
            let tempData = JSON.parse(JSON.stringify(data));
            let reg = /name/gi;
            let temp = JSON.parse(JSON.stringify(tempData).replace(reg, "title"));
            this.sortSelect = temp;
        },
        removeSelect() {
            let temp = this.sortSelect.filter((item) => {
                if (!("checked" in item)) {
                    return item;
                }
            });
            this.sortSelect = temp;
        },
        removeAll() {
            this.$refs.selection.selectAll(false);
            this.sortSelect = [];
            this.$emit("handleSelect", this.sortSelect)
        },

        currentPage(val) {
            //当前页
            this.curPage = val;
            this.getList();
        },
        hadlePageSize(val) {
            //每页的条数

            this.pageSize = val;
            this.getList();
        },

        // ---------------------------分页记忆---------------------------
        /**
         * @param {*} selection 已选项数据
         * @param {*} row 刚选择的项数据
         * @description 勾选table某一项时触发
         */
        onSelect(selection, row) {
            let obj = {
                id: row.id,
                title: row.name,
                custGlobalId: row.custGlobalId
            }
            // this.tempArr.push(row.id) // 将该条数据添加到tempArr中
            this.sortSelect.push(obj) // 将该条数据添加到tempArr中
            this.tableData.forEach(item => { // 本页数据中找到该条勾选数据并添加属性_checked = true
                if (item.id === row.id) {
                    item['_checked'] = true
                }
            })
            this.$emit("handleSelect", this.sortSelect)
        },

        /**
         * @param {*} selection 已选项数据
         * @param {*} row 刚取消选择的项数据
         * @description 取消table某一项时触发
         */
        onSelectCancel(selection, row) {
            this.sortSelect.forEach((item, index) => { // tempArr中找到该条数据并删除
                if (row.id === item.id) {
                    this.sortSelect.splice(index, 1)
                }
            })
            this.$emit("handleSelect", this.sortSelect)

        },

        /**
         * @param {*} selection 已选项数据
         * @description table全选时触发
         */
        onSelectAll(selection) {
            this.sortSelect = [];
            selection.forEach(item => { // 将本页全部勾选添加到tempArr中
                // this.tempArr.push(item.id)
                let obj = {
                    id: item.id,
                    title: item.name,
                    custGlobalId: item.custGlobalId
                }
                this.sortSelect.push(obj)
            })

            this.sortSelect = Array.from(new Set(this.sortSelect)) // 去重
            this.$emit("handleSelect", this.sortSelect)
        },

        /**
         * @param {*} selection 已选项数据
         * @description table取消全选时触发
         */
        onSelectAllCancel() {
            this.sortSelect = [];
            this.$emit("handleSelect", this.sortSelect)
        },

        /**
         * @param {*} datas 当前页数据
         * @description 还原有勾选的数据
         */
        handleTableChecked(datas) {
            this.sortSelect.forEach(item => { // 判断本页数据状态
                datas.forEach(e => {
                    if (item.id === e.id) {
                        e['_checked'] = true
                    }
                })
            })
        },

    },
};
</script>

<style lang="less" scoped>
.selectUser {
    .ivu-layout-sider {
        background: #fff;
        min-height: 200px;
        .ivu-layout-sider-children {
            .aside {
                // padding: 20px;
                border: 1px solid rgba(000, 000, 000, 0.1);

                .title {
                    padding: 20px;
                    border-bottom: 1px solid rgba(000, 000, 000, 0.1);
                }
            }

            background: #fff;
            height: auto;
        }
    }

    .content {
        display: flex;
        height: 500px;

        .tabList {
            width: 800px;
            height: 500px;
            padding-top: 10px;
            border-right: 1px solid rgba(000, 000, 000, 0.1);

            .ivu-table-wrapper {
                min-height: 420px;
            }
        }

        .buttonList {
            width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 500px;
            height: 100%;
        }

        .selectList {
            width: 160px;
            overflow-y: scroll;
            height: 500px;

            border-radius: 0px;
            // border-top: none;
            // border-bottom: none;
            border: 1px solid rgba(000, 000, 000, 0.1);

            .ivu-checkbox-group-item {
                display: block;
                margin: 20px 50px;
            }
        }
    }
    /deep/.ivu-tooltip-dark {
       display: none;
    }
}
</style>
